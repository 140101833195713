/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {


      init: function() {
        // smooth scrolling
        // var scroll = new SmoothScroll('a[href*="#"]');

        $(document).ready(function(){
         $('#collapseBenefits1').on('click', function () {
          var text=$('#collapseBenefits1').text();
          if(text === "Show more features & benefits"){
            $(this).html('Show less features & benefits');


          } else{
            $(this).text('Show more features & benefits');
         }
        });

         $('#collapseBenefits1').on('click', function () {


           });

        });

        // mmenu

          $("#menu").mmenu({
            "extensions": [
              "position-back",
              "position-right"
          ]
           });


        // JavaScript to be fired on all pages
        // breakpoint and up
        jQuery(document).on('click', '.mega-dropdown', function(e) {
      e.stopPropagation();
      });


      $('.menu-products a').click(function(e){
          $( ".js-navbar-collapse2" ).removeClass( "show" );
      });

      $('.menu-gavita-lighting-academy a').click(function(e){
          $( ".js-navbar-collapse" ).removeClass( "show" );
      });



      },

      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        $(function() {

            var $allVideos = $("iframe[src^='//player.vimeo.com'], iframe[src^='//www.youtube.com'], object, embed"),
            $fluidEl = $("figure");

        	$allVideos.each(function() {

        	  $(this)
        	    // jQuery .data does not work on object/embed elements
        	    .attr('data-aspectRatio', this.height / this.width)
        	    .removeAttr('height')
        	    .removeAttr('width');

        	});

        	$(window).resize(function() {

        	  var newWidth = $fluidEl.width();
        	  $allVideos.each(function() {

        	    var $el = $(this);
        	    $el
        	        .width(newWidth)
        	        .height(newWidth * $el.attr('data-aspectRatio'));

        	  });

        	}).resize();

        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
